import { useEffect, useLayoutEffect, useState } from "react";

import React from 'react';
import Plot from 'react-plotly.js';
import { clone } from "../common/functions";

const Results = (p: {
    result: any
}) => {
    /*
    {
        "iluminacion" : [],
        "electrodomesticos" : [],
        "aclimatadores" : [],
        "transporte" : [], 
        "vacaciones" : [],
        "alimentos" : [],
        "papeles" : []
    }
    */

    const [r, setR] = useState(null);
    const [ch, setCh] = useState({ layout: null as any, data: null as any });

    useLayoutEffect(() => {
        if (p.result) {
            p.result["res"] = (p.result["iluminacion"] +
                p.result["electrodomesticos"] +
                p.result["aclimatadores"] +
                p.result["transporte"] +
                p.result["vacaciones"] +
                p.result["papeles"] +
                p.result["alimentos"]).toString();

            setR(p.result);
        }
    }, [p])

    useLayoutEffect(() => {
        if (r != null) {

            let reV = clone(r);
            delete reV["res"];

            var data = [
                {
                    values: Object.values(reV),
                    labels: Object.keys(reV).map((k: string) => {
                        return k[0].toUpperCase() + k.slice(1);
                    }),
                    type: 'pie',
                    textinfo: "label+percent",
                    textposition: "outside",
                    automargin: true
                }
            ];

            var layout = {
                height: 400,
                width: 400,
                margin: { "t": 0, "b": 0, "l": 0, "r": 0 },
                showlegend: false
            };

            setCh({ layout: layout, data: data });

        }
    }, [r])

    if(r == null){
        return <div>
            <span>Cargando ...</span>
        </div>
    }

    return <div className="results w-100 d-flex flex-column align-items-center">
        <h4>Tus resultados</h4>

        <div className="d-flex flex-column align-items-center mb-5">
            <span className="px-2" style={{textAlign: "center"}}>Tu huella es de <b>{parseFloat(r!["res"]).toFixed(4)} </b><i>Kg CO2eq</i></span>

            <span className="px-2" style={{textAlign: "center"}}>Equivale a <b>{parseFloat((parseFloat(r!["res"]) / 170).toString()).toFixed(4)} <i className="bi bi-tree" style={{ color: "green" }}></i></b> árboles necesarios para absorber de la atmósfera tus emisiones de C02 anuales</span>
        </div>
        {
            ch.data ?
                <Plot
                    data={ch.data}
                    layout={ch.layout}
                    config={
                        {
                            responsive: true
                        }
                    }
                />
                :
                <></>
        }

        {
            /*
            <ul>
            <li>
                <span>Luz Eléctrica</span>
                <label htmlFor="">{p.result["iluminacion"]}</label>
            </li>
            <li>
                <span>Artículos eléctricos</span>
                <label htmlFor="">{p.result["electrodomesticos"]}</label>
            </li>
            <li>
                <span>Aclimatadores</span>
                <label htmlFor="">{p.result["aclimatadores"]}</label>
            </li>
            <li>
                <span>Transporte </span>
                <label htmlFor="">{p.result["transporte"]}</label>
            </li>
            <li>
                <span>Vacaciones</span>
                <label htmlFor="">{p.result["vacaciones"]}</label>
            </li>
            <li>
                <span>Papeles</span>
                <label htmlFor="">{p.result["papeles"]}</label>
            </li>
            <li>
                <span>Dieta</span>
                <label htmlFor="">{p.result["alimentos"]}</label>
            </li>
        </ul>
            */
        }
    </div>
}

export default Results;