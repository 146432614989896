import { useLayoutEffect, useRef, useState } from "react";
import { clone } from "../common/functions";
import { TransporteRel } from "../common/rel";

const Transporte = (p: {
    changeSlide: (index: number) => any,
    onChange: any,
    labels: any
}) => {
    const [transp, setTransp] = useState([] as { t: string, h: number, f: number }[]);
    const [vac, setVac] = useState([] as { t: string, h: number, f: number }[]);

    let Vacaciones = () => {
        const typeRef = useRef({} as HTMLSelectElement);
        const hoursRef = useRef({} as HTMLInputElement);
        const freqRef = useRef({} as HTMLInputElement);

        const addAcli = () => {
            setVac([...vac, { t: typeRef.current.value, h: parseInt(hoursRef.current.value), f: parseInt(freqRef.current.value) }]);

            typeRef.current.value = "t1";
            hoursRef.current.value = "1";
            freqRef.current.value = "1";
        }

        const removeAcli = (index: number) => {
            let lps: Array<{ t: string, h: number, f: number }> = clone(vac);
            setVac(lps.filter((e: any, idx: number) => {
                return idx !== index;
            }));
        }

        return <li className="container flex-column align-items-start gap-3 ">
            <div className="d-flex flex-row gap-2 align-items-center w-100 mb-4">
                <i className="bi bi-truck-front "></i>
                <span className="">Vacaciones o escapadas de fin de semana</span>
            </div>

            <div className="row gap-2 align-items-end w-100 mx-0 mb-2">
                <div className="col px-0">
                    <label htmlFor="" className="form-label">Medio</label>
                    <select name="" id="" className="form-select" ref={typeRef}>
                        {
                            Object.keys(TransporteRel).map((k: string) => {
                                return <option value={k}>{(TransporteRel as any)[k].n}</option>
                            })
                        }
                    </select>
                </div>

                <div className="col px-0">
                    <label className="form-label">Distancia en Km: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={hoursRef} />
                </div>

                <div className="col px-0">
                    <label className="form-label">Frecuencia anual: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={freqRef} />
                </div>

                <button className="btn btn-success col" onClick={addAcli}><i className="bi bi-plus"></i>Agregar</button>
            </div>

            {
                vac.length > 0 ?
                    <>
                        <div className="form-control w-100 container" >
                            {
                                vac.map((r: { t: string, h: number, f: number }, idx) => {
                                    return <div className="d-flex flex-row align-items-center justify-content-between w-100 gap-2 px-2 py-1">
                                        <span>{((TransporteRel as any)[r.t]).n}</span>
                                        <div className="d-flex flex-row align-items-center gap-4">
                                            <small>{r.h} : H/d</small>
                                            <small>{r.f} : F/s</small>
                                            <button className="btn btn-danger px-1 py-0 ml-2" onClick={() => {
                                                removeAcli(idx);
                                            }}><i className="bi bi-dash"></i></button>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </>
                    :
                    <>
                    </>
            }
        </li>
    }

    let Vehiculo = () => {
        const typeRef = useRef({} as HTMLSelectElement);
        const hoursRef = useRef({} as HTMLInputElement);
        const freqRef = useRef({} as HTMLInputElement);

        const addAcli = () => {
            setTransp([...transp, { t: typeRef.current.value, h: parseInt(hoursRef.current.value), f: parseInt(freqRef.current.value) }]);

            typeRef.current.value = "t1";
            hoursRef.current.value = "1";
            freqRef.current.value = "1";
        }

        const removeAcli = (index: number) => {
            let lps: Array<{ t: string, h: number, f: number }> = clone(transp);
            setTransp(lps.filter((e: any, idx: number) => {
                return idx !== index;
            }));
        }

        return <li className="container flex-column align-items-start gap-3 ">
            <div className="d-flex flex-row gap-2 align-items-center w-100 mb-4">
                <i className="bi bi-truck-front "></i>
                <span className="">Medios de transporte</span>
            </div>

            <div className="row gap-2 align-items-end w-100 mx-0 mb-2">
                <div className="col px-0">
                    <label htmlFor="" className="form-label">Medio</label>
                    <select name="" id="" className="form-select" ref={typeRef}>
                        {
                            Object.keys(TransporteRel).map((k: string) => {
                                return <option value={k}>{(TransporteRel as any)[k].n}</option>
                            })
                        }
                    </select>
                </div>

                <div className="col px-0">
                    <label className="form-label">Km diarios: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={hoursRef} />
                </div>

                <div className="col px-0">
                    <label className="form-label">Frecuencia semanal: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={freqRef} />
                </div>

                <button className="btn btn-success col" onClick={addAcli}><i className="bi bi-plus"></i>Agregar</button>
            </div>

            {
                transp.length > 0 ?
                    <>
                        <div className="form-control w-100 container" >
                            {
                                transp.map((r: { t: string, h: number, f: number }, idx) => {
                                    return <div className="d-flex flex-row align-items-center justify-content-between w-100 gap-2 px-2 py-1">
                                        <span>{((TransporteRel as any)[r.t]).n}</span>
                                        <div className="d-flex flex-row align-items-center gap-4">
                                            <small>{r.h} : H/d</small>
                                            <small>{r.f} : F/s</small>
                                            <button className="btn btn-danger px-1 py-0 ml-2" onClick={() => {
                                                removeAcli(idx);
                                            }}><i className="bi bi-dash"></i></button>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </>
                    :
                    <>
                    </>
            }
        </li>
    }

    useLayoutEffect(() => {
        p.onChange(
            {
                "iluminacion": p.labels["iluminacion"],
                "electrodomesticos": p.labels["electrodomesticos"],
                "aclimatadores": p.labels["aclimatadores"],
                "transporte": transp,
                "vacaciones": p.labels["vacaciones"],
                "alimentos": p.labels["alimentos"],
                "papeles": p.labels["papeles"],
                "user": p.labels["user"],
            }
        );
    }, [transp]);

    useLayoutEffect(() => {
        p.onChange(
            {
                "iluminacion": p.labels["iluminacion"],
                "electrodomesticos": p.labels["electrodomesticos"],
                "aclimatadores": p.labels["aclimatadores"],
                "transporte": p.labels["transporte"],
                "vacaciones": vac,
                "alimentos": p.labels["alimentos"],
                "papeles": p.labels["papeles"],
                "user": p.labels["user"],
            }
        );
    }, [vac]);

    return <div className="d-flex flex-column w-100 h-100">
        <div className="vivienda-form form ">
            <ul className="gap-3 d-flex flex-column  px-0">
                <Vehiculo />
                <hr />
                <Vacaciones />
            </ul>
        </div>
        <div className="d-flex flex-row justify-content-between pt-4 px-2">
            <button className="btn btn-secondary d-flex align-items-center flex-row gap-2 px-4 py-2" onClick={() => {
                p.changeSlide(0)
            }}>
                <i className="bi bi-chevron-left"></i>
                Anterior
            </button>
            <button className="btn btn-primary d-flex align-items-center flex-row gap-2 px-4 py-2" onClick={() => {
                p.changeSlide(2)
            }}>
                Siguiente
                <i className="bi bi-chevron-right"></i>
            </button>
        </div>
    </div>
}

export default Transporte;