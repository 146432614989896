import { AclimatadoresRel, AlimentosRel, ElectrodomesticosRel, PapelesRel, TransporteRel, ViviendaRel } from "./rel"

export const clone = (elem: any): any => {
    return JSON.parse(JSON.stringify(elem))
}

export const recalculate = (labels: any): any => {
    let res = {
        "iluminacion": 0,
        "electrodomesticos": 0,
        "aclimatadores": 0,
        "transporte": 0,
        "vacaciones": 0,
        "alimentos": 0,
        "papeles": 0,
        "user" : labels["user"]
    }

    Object.keys(labels).map((k: string) => {
        switch (k) {
            case "iluminacion": res["iluminacion"] = calcIlum(labels["iluminacion"]); break;

            case "electrodomesticos": res["electrodomesticos"] = calcElectro(labels["electrodomesticos"]); break;

            case "aclimatadores": res["aclimatadores"] = calcAclim(labels["aclimatadores"]); break;

            case "transporte": res["transporte"] = calcTrans(labels["transporte"]); break;

            case "vacaciones": res["vacaciones"] = calcVac(labels["vacaciones"]); break;

            case "alimentos": res["alimentos"] = calcAlim(labels["alimentos"]); break;

            case "papeles": res["papeles"] = calcPap(labels["papeles"]); break;
        }
    });

    return res;
}

const calcPap = (v: { h: number, t: string }[]): number => {
    let r = 0;

    for (let i in v) {
        let res = 0;
        let rel = (PapelesRel as any)[v[i].t];

        //Emisiones por anio
        switch (v[i].t) {
            case "p1": res = 0.00616 * v[i].h; break;
            case "p2": res = 0.00768 * v[i].h; break;
            case "p3": res = 0.00616 * v[i].h; break;
        }

        r += res;
    }

    return r;
}

const calcAlim = (v: { h: number, t: string }[]): number => {
    let r = 0;

    for (let i in v) {
        let res = 0;
        let rel = (AlimentosRel as any)[v[i].t];

        //Emisiones por anio
        res = (rel.v / 5) * v[i].h * 52;

        r += res;
    }

    return r;
}

const calcVac = (v: { h: number, t: string, f: number }[]): number => {
    let r = 0;

    for (let i in v) {
        let res = 0;
        let rel = (TransporteRel as any)[v[i].t];

        //Emisiones por anio
        res = v[i].h * v[i].f * rel.v * 2;

        r += res;
    }

    return r;
}

const calcTrans = (v: { h: number, t: string, f: number }[]): number => {
    let r = 0;

    for (let i in v) {
        let res = 0;
        let rel = (TransporteRel as any)[v[i].t];

        //Emisiones por anio
        res = v[i].h * v[i].f * rel.v * 52;

        r += res;
    }

    return r;
}

const calcAclim = (v: { h: number, t: string, f: number }[]): number => {
    let r = 0;

    for (let i in v) {
        let res = 0;
        let rel = (AclimatadoresRel as any)[v[i].t];

        //Emisiones por anio
        res = v[i].h * v[i].f * rel.v * 90;

        r += res;
    }

    return r;
}

const calcElectro = (v: { h: number, t: string, f: number }[]): number => {
    let r = 0;

    for (let i in v) {
        let res = 0;
        let rel = (ElectrodomesticosRel as any)[v[i].t];

        //Emisiones por anio
        res = v[i].h * v[i].f * rel.v * 52;

        r += res;
    }

    return r;
}

const calcIlum = (v: { q: number, t: string, f: number }[]): number => {
    let r = 0;

    for (let i in v) {
        let res = 0;
        let rel = (ViviendaRel as any)[v[i].t];

        //Emisiones por dia
        res = v[i].q * v[i].f * rel.v;

        //Eminisiones por anio
        res = res * 365;

        r += res;
    }

    return r;
}