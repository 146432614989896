
import { Swiper as SwiperR, SwiperRef, SwiperSlide } from 'swiper/react';
import Swiper, { Pagination } from "swiper";
import 'swiper/swiper-bundle.css';
import Vivienda from './components/Vivienda';

import "bootstrap-icons/font/bootstrap-icons.scss";
import { useEffect, useRef, useState } from 'react';
import Transporte from './components/Transporte';
import Dieta from './components/Dieta';
import Papeles from './components/Papeles';
import Results from './components/Results';
import { recalculate } from './common/functions';
import Login from './components/Login';

import axios from "axios";

let App = () => {
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      //return '<span class="' + className + '">' + (index + 1) + "</span>";
      return "<span class=" + className + "></span>";
    },
  };

  const [swiper, setSwiper] = useState<Swiper>(null as any);

  const slideTo = (index: number) => {
    try {
      swiper!.slideTo(index + 1)
    } catch (er) { }
  };

  const swiperRef = useRef({} as SwiperRef);

  const [labels, setLabels] = useState(
    {
      "iluminacion": [],
      "electrodomesticos": [],
      "aclimatadores": [],
      "transporte": [],
      "vacaciones": [],
      "alimentos": [],
      "papeles": [],
      "user": { email: "", name: "" }
    }
  );

  const [result, setResult] = useState(
    {
      "iluminacion": 0,
      "electrodomesticos": 0,
      "aclimatadores": 0,
      "transporte": 0,
      "vacaciones": 0,
      "alimentos": 0,
      "papeles": 0,
      "user": { email: "", name: "" }
    }
  );

  const complete = () => {
    slideTo(4);

    setResult(recalculate(labels));

    
  }

  useEffect(() => {
    new Promise((res, rej) => {
      axios.get("https://administrador.nuevaeconomia.com.bo/api/saveco2.php?v="+(JSON.stringify(result))).then((r: { data: any }) => {
        res(r.data);
      }).catch((err: any) => {
        rej(err);
      })
    })

    console.debug(result)
  }, [result])

  return (
    <div className="app">
      <center>
        <h2 className='title px-2'>Calcula tu impacto medioambiental</h2>
      </center>

      <SwiperR
        style={{ maxWidth: "100vw", width: "100%", maxHeight: "80vh", height: "100%" }}
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{

        }}
        pagination={pagination}
        modules={[Pagination]}
        direction="vertical"
        className="swiper-calc"
        onSwiper={setSwiper}
        ref={swiperRef}
        enabled={true}
      >

        <SwiperSlide
          className={""}
          onClick={() => { }}
          style={{}}
        >
          <Login
            onChange={setLabels}
            changeSlide={(idx: number) => {
              slideTo(idx)
            }} />
        </SwiperSlide>

        <SwiperSlide
          className={""}
          onClick={() => { }}
          style={{}}
        >
          <Vivienda
            onChange={setLabels}
            labels={labels}
            changeSlide={(idx: number) => {
              slideTo(idx)
            }} />
        </SwiperSlide>

        <SwiperSlide
          className={""}
          onClick={() => { }}
          style={{}}
        >
          <Transporte
            onChange={setLabels}
            labels={labels}
            changeSlide={(idx: number) => {
              slideTo(idx)
            }} />
        </SwiperSlide>

        <SwiperSlide
          className={""}
          onClick={() => { }}
          style={{}}
        >
          <Dieta
            onChange={setLabels}
            labels={labels}
            changeSlide={(idx: number) => {
              slideTo(idx)
            }} />
        </SwiperSlide>

        <SwiperSlide
          className={""}
          onClick={() => { }}
          style={{}}
        >
          <Papeles
            onChange={setLabels}
            labels={labels}
            changeSlide={(idx: number) => {
              slideTo(idx)
            }}
            onComplete={complete}
          />
        </SwiperSlide>
        <SwiperSlide
          className={""}
          onClick={() => { }}
          style={{}}
        >
          <Results result={result} />
        </SwiperSlide>
      </SwiperR>

    </div>
  );
}

export default App;
