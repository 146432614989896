

export const ViviendaRel = {
    "l1": { v: 0.00043300, n: "Incandescente de 100 W" },
    "l2": { v: 0.00004300, n: "Led 9 W" },
    "l3": { v: 0.00013000, n: "Tubo Fluorescente 30 W" }
}

export const ElectrodomesticosRel = {
    "e1": { v: 0.0106, n: "PC" },
    "e2": { v: 0.0290, n: "Radio" },
    "e3": { v: 0.3629, n: "Impresora" },
    "e4": { v: 0.3097, n: "Microondas" },
    //"e5": { v: 0.1210, n: "Dispenser" },
    "e6": { v: 1.1613, n: "Caldera eléctrica" },
    "e7": { v: 0.0852, n: "Lavadora" },
    "e8": { v: 0.0564, n: "Refrigerador" },
    "e9": { v: 0.0263, n: "Televisión" },
    "e10": { v: 0.1881, n: "Secadora de pelo" },
    "e11": { v: 0.0903, n: "Secadora de ropa" },
}

export const AclimatadoresRel = {
    "ac1": { v: 0.0656, n: "Ventilador" },
    "ac2": { v: 0.7384, n: "Aire acondicionado" },
    "ac3": { v: 0.5558, n: "Estufa a gas" },
    "ac4": { v: 1.0260, n: "Calentador portátil" },
    "ac5": { v: 0.4924, n: "Radiador eléctrico" },
    "ac6": { v: 0.8208, n: "Estufa infrarroja" },
    "ac7": { v: 0.6156, n: "Estufa cuarzo" },
}

export const TransporteRel = {
    "t1": { v: 0, n: "Pie" },
    "t2": { v: 0, n: "Bicicleta" },
    "t3": { v: 0.07, n: "Moto" },
    "t4": { v: 0.2, n: "Auto GNV (particular o público)" },
    "t5": { v: 0.28, n: "Auto gasolina (particular o público)" },
    "t6": { v: 0.3, n: "Auto diesel (particular o público)" },
    "t7": { v: 0.14, n: "Camioneta gasolina" },
    "t8": { v: 0.18, n: "Camioneta diesel" },
    "t9": { v: 0.03, n: "Bús" },
    "t10": { v: 0.3, n: "Minibus" },
    "t11": { v: 0.32, n: "Avión" }
}

export const AlimentosRel = {
    "acl1": { v: 1.3, n: "Manzana " },
    "acl2": { v: 1.1, n: "Tomate" },
    "acl3": { v: 2.9, n: "Papa" },
    "acl4": { v: 2.8, n: "Huevos" },
    "acl5": { v: 6.9, n: "Pollo" },
    "acl6": { v: 17, n: "Carne vacuna" },
    "acl7": { v: 1.22, n: "Leche" },
    "acl8": { v: 8.5, n: "Queso" },
    "acl9": { v: 0.45, n: "Pan" },
}

export const PapelesRel = {
    "p1": { v: 4.6654, n: "Hoja A4" },
    "p2": { v: 5.8179, n: "Hoja Oficio" },
    "p3": { v: 4.6654, n: "Hoja Escolar n°3" },
}