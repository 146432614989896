import { useLayoutEffect, useRef, useState } from "react";
import { clone } from "../common/functions";
import { PapelesRel } from "../common/rel";

const Papeles = (p: {
    changeSlide: (index: number) => any,
    onChange: any,
    labels: any,
    onComplete: any
}) => {
    const [alim, setAlim] = useState([] as { t: string, h: number }[]);

    let PapelesR = () => {
        const typeRef = useRef({} as HTMLSelectElement);
        const hoursRef = useRef({} as HTMLInputElement);

        const addAcli = () => {
            setAlim([...alim, { t: typeRef.current.value, h: parseInt(hoursRef.current.value) }]);

            typeRef.current.value = "p1";
            hoursRef.current.value = "1";
        }

        const removeAcli = (index: number) => {
            let lps: Array<{ t: string, h: number, f: number }> = clone(alim);
            setAlim(lps.filter((e: any, idx: number) => {
                return idx !== index;
            }));
        }

        return <li className="container flex-column align-items-start gap-3 ">
            <div className="d-flex flex-row gap-2 align-items-center w-100 mb-4">
                <i className="bi bi-paperclip "></i>
                <span className="">Cantidad de papeles usados en un año</span>
            </div>

            <div className="row gap-2 align-items-end w-100 mx-0 mb-2">
                <div className="col px-0">
                    <label htmlFor="" className="form-label">Papel</label>
                    <select name="" id="" className="form-select" ref={typeRef}>
                        {
                            Object.keys(PapelesRel).map((k: string) => {
                                return <option value={k}>{(PapelesRel as any)[k].n}</option>
                            })
                        }
                    </select>
                </div>

                <div className="col px-0">
                    <label className="form-label">Hojas anuales: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={hoursRef} />
                </div>

                <button className="btn btn-success col" onClick={addAcli}><i className="bi bi-plus"></i>Agregar</button>
            </div>

            {
                alim.length > 0 ?
                    <>
                        <div className="form-control w-100 container" >
                            {
                                alim.map((r: { t: string, h: number }, idx) => {
                                    return <div className="d-flex flex-row align-items-center justify-content-between w-100 gap-2 px-2 py-1">
                                        <span>{((PapelesRel as any)[r.t]).n}</span>
                                        <div className="d-flex flex-row align-items-center gap-4">
                                            <small>{r.h} : H/d</small>
                                            <button className="btn btn-danger px-1 py-0 ml-2" onClick={() => {
                                                removeAcli(idx);
                                            }}><i className="bi bi-dash"></i></button>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </>
                    :
                    <>
                    </>
            }
        </li>
    }

    useLayoutEffect(() => {
        p.onChange(
            {
                "iluminacion": p.labels["iluminacion"],
                "electrodomesticos": p.labels["electrodomesticos"],
                "aclimatadores": p.labels["aclimatadores"],
                "transporte": p.labels["transporte"],
                "vacaciones": p.labels["vacaciones"],
                "alimentos": p.labels["alimentos"],
                "papeles": alim,
                "user": p.labels["user"],
            }
        );
    }, [alim]);

    return <div className="d-flex flex-column w-100 h-100">
        <div className="vivienda-form form ">
            <ul className="gap-3 d-flex flex-column  px-0">
                <PapelesR />
            </ul>
        </div>
        <div className="d-flex flex-row justify-content-between pt-4 px-2">
            <button className="btn btn-secondary d-flex align-items-center flex-row gap-2 px-4 py-2" onClick={() => {
                p.changeSlide(2)
            }}>
                <i className="bi bi-chevron-left"></i>
                Anterior
            </button>
            <button className="btn btn-primary d-flex align-items-center flex-row gap-2 px-4 py-2" onClick={() => {
                p.onComplete()
            }}>
                Completar
            </button>
        </div>
    </div>
}

export default Papeles;