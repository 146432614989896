import { useRef, useState } from "react";

const Login = (p: {
    changeSlide: (index: number) => any,
    onChange: any
}) => {
    const nameRef = useRef({} as HTMLInputElement);
    const emailRef = useRef({} as HTMLInputElement);

    const [isValid, setIsValid] = useState(false);

    const analize = () => {
        try{
            if (
                nameRef.current.value.length > 0 &&
                emailRef.current.value.length > 0
            ) {
                setIsValid(true);
            }else{
                setIsValid(false);
            }
        }catch(err){}
    }

    return <div className="login d-flex align-items-center justify-content-center m-auto" style={{ width: "70%" }}>
        <div className="form-control d-flex flex-column w-100">
            <label htmlFor="" className="form-label">Nombre completo: </label>
            <input onChange={analize} ref={nameRef} type="text" disabled={false} className="d-block mb-4 form-control" />

            <label htmlFor="" className="form-label">Correo: </label>
            <input onChange={analize} ref={emailRef} type="text" disabled={false} className="form-control mb-4" />

            <button disabled={!isValid} className="btn btn-primary" onClick={() => {
                p.onChange(
                    {
                        "iluminacion": "",
                        "electrodomesticos": "",
                        "aclimatadores": "",
                        "transporte": "",
                        "vacaciones": "",
                        "alimentos": "",
                        "papeles": "",
                        "user": { email: emailRef.current.value, name: nameRef.current.value },
                    }
                );
                p.changeSlide(0);
            }}>Comenzar</button>
        </div>
    </div>
}

export default Login;