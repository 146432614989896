import { useLayoutEffect, useRef, useState } from "react";
import { clone } from "../common/functions";
import { AclimatadoresRel, ElectrodomesticosRel, ViviendaRel } from "../common/rel";

const Vivienda = (p: {
    changeSlide: (index: number) => any,
    onChange: any,
    labels: any
}) => {

    const [lamps, setLamps] = useState([] as { t: string, q: number, f: number }[]);
    const [electros, setElectros] = useState([] as { t: string, h: number, f: number }[]);
    const [acli, setAcli] = useState([] as { t: string, h: number, f: number }[]);

    let Iluminacion = () => {
        const typeRef = useRef({} as HTMLSelectElement);
        const quantityRef = useRef({} as HTMLInputElement);
        const hRef = useRef({} as HTMLInputElement);

        const addLamp = () => {
            setLamps([...lamps, { t: typeRef.current.value, q: parseInt(quantityRef.current.value), f:  parseInt(hRef.current.value)}]);

            typeRef.current.value = "l1";
            quantityRef.current.value = "1";
            hRef.current.value = "1";
        }

        const removeLamp = (index: number) => {
            let lps: Array<{ t: string, q: number, f: number }> = clone(lamps);
            setLamps(lps.filter((e: any, idx: number) => {
                return idx !== index;
            }));
        }

        return <li className="container flex-column align-items-start gap-3 ">
            <div className="d-flex flex-row gap-2 align-items-center w-100 mb-4">
                <i className="bi bi-lamp "></i>
                <span className="">Iluminación</span>
            </div>

            <div className="row gap-2 align-items-end w-100 mx-0 mb-2">
                <div className="col px-0">
                    <label htmlFor="" className="form-label">Tipo de lámpara:</label>
                    <select name="" id="" className="form-select" ref={typeRef}>
                        <option value="l1">Incandescente de 100 W</option>
                        <option value="l2">Led 9 W</option>
                        <option value="l3">Tubo Fluorescente 30 W</option>
                    </select>
                </div>

                <div className="col px-0">
                    <label className="form-label">Cantidad: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={quantityRef} />
                </div>

                <div className="col px-0">
                    <label className="form-label">Horas por día: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={hRef} />
                </div>

                <button className="btn btn-success col" onClick={addLamp}><i className="bi bi-plus"></i>Agregar</button>
            </div>

            {
                lamps.length > 0 ?
                    <>
                        <div className="form-control w-100 container" >
                            {
                                lamps.map((r: { t: any, q: number, f: number }, idx) => {
                                    return <div className="d-flex flex-row align-items-center justify-content-between w-100 gap-2 px-2 py-1">
                                        <span>{((ViviendaRel as any)[r.t]).n}</span>
                                        <div className="d-flex flex-row align-items-center gap-4">
                                            <small>Cant: {r.q}</small>
                                            <small>Hrs: {r.f}</small>
                                            <button className="btn btn-danger px-1 py-0 ml-2" onClick={() => {
                                                removeLamp(idx);
                                            }}><i className="bi bi-dash"></i></button>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </>
                    :
                    <>
                    </>
            }
        </li>
    }

    let Electrodomesticos = () => {
        const typeRef = useRef({} as HTMLSelectElement);
        const hoursRef = useRef({} as HTMLInputElement);
        const freqRef = useRef({} as HTMLInputElement);

        const addElectro = () => {
            setElectros([...electros, { t: typeRef.current.value, h: parseInt(hoursRef.current.value), f: parseInt(freqRef.current.value) }]);

            typeRef.current.value = "e1";
            hoursRef.current.value = "1";
            freqRef.current.value = "1";
        }

        const removeElectro = (index: number) => {
            let lps: Array<{ t: string, h: number, f: number }> = clone(electros);
            setElectros(lps.filter((e: any, idx: number) => {
                return idx !== index;
            }));
        }

        return <li className="container flex-column align-items-start gap-3 ">
            <div className="d-flex flex-row gap-2 align-items-center w-100 mb-4">
                <i className="bi bi-house "></i>
                <span className="">Electrodomesticos</span>
            </div>

            <div className="row gap-2 align-items-end w-100 mx-0 mb-2">
                <div className="col px-0">
                    <label htmlFor="" className="form-label">Aparato:</label>
                    <select name="" id="" className="form-select" ref={typeRef}>
                        {
                            Object.keys(ElectrodomesticosRel).map((k: string) => {
                                return <option value={k}>{(ElectrodomesticosRel as any)[k].n}</option>
                            })
                        }
                    </select>
                </div>

                <div className="col px-0">
                    <label className="form-label">Horas diarias: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={hoursRef} />
                </div>

                <div className="col px-0">
                    <label className="form-label">Frecuencia semanal: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={freqRef} />
                </div>

                <button className="btn btn-success col" onClick={addElectro}><i className="bi bi-plus"></i>Agregar</button>
            </div>

            {
                electros.length > 0 ?
                    <>
                        <div className="form-control w-100 container" >
                            {
                                electros.map((r: { t: string, h: number, f: number }, idx) => {
                                    return <div className="d-flex flex-row align-items-center justify-content-between w-100 gap-2 px-2 py-1">
                                        <span>{((ElectrodomesticosRel as any)[r.t]).n}</span>
                                        <div className="d-flex flex-row align-items-center gap-4">
                                            <small>{r.h} : H/d</small>
                                            <small>{r.f} : F/s</small>
                                            <button className="btn btn-danger px-1 py-0 ml-2" onClick={() => {
                                                removeElectro(idx);
                                            }}><i className="bi bi-dash"></i></button>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </>
                    :
                    <>
                    </>
            }
        </li>
    }

    let Aclimatadores = () => {
        const typeRef = useRef({} as HTMLSelectElement);
        const hoursRef = useRef({} as HTMLInputElement);
        const freqRef = useRef({} as HTMLInputElement);

        const addAcli = () => {
            setAcli([...acli, { t: typeRef.current.value, h: parseInt(hoursRef.current.value), f: parseInt(freqRef.current.value) }]);

            typeRef.current.value = "ac1";
            hoursRef.current.value = "1";
            freqRef.current.value = "1";
        }

        const removeAcli = (index: number) => {
            let lps: Array<{ t: string, h: number, f: number }> = clone(acli);
            setAcli(lps.filter((e: any, idx: number) => {
                return idx !== index;
            }));
        }

        return <li className="container flex-column align-items-start gap-3 ">
            <div className="d-flex flex-row gap-2 align-items-center w-100 mb-4">
                <i className="bi bi-fan "></i>
                <span className="">Aclimatadores</span>
            </div>

            <div className="row gap-2 align-items-end w-100 mx-0 mb-2">
                <div className="col px-0">
                    <label htmlFor="" className="form-label">Aparato:</label>
                    <select name="" id="" className="form-select" ref={typeRef}>
                        {
                            Object.keys(AclimatadoresRel).map((k: string) => {
                                return <option value={k}>{(AclimatadoresRel as any)[k].n}</option>
                            })
                        }
                    </select>
                </div>

                <div className="col px-0">
                    <label className="form-label">Horas diarias: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={hoursRef} />
                </div>

                <div className="col px-0">
                    <label className="form-label">Frecuencia semanal: </label>
                    <input className="form-control" type="number" min={0} max={10000} defaultValue={1} placeholder="Cantidad" ref={freqRef} />
                </div>

                <button className="btn btn-success col" onClick={addAcli}><i className="bi bi-plus"></i>Agregar</button>
            </div>

            {
                acli.length > 0 ?
                    <>
                        <div className="form-control w-100 container" >
                            {
                                acli.map((r: { t: string, h: number, f: number }, idx) => {
                                    return <div className="d-flex flex-row align-items-center justify-content-between w-100 gap-2 px-2 py-1">
                                        <span>{((AclimatadoresRel as any)[r.t]).n}</span>
                                        <div className="d-flex flex-row align-items-center gap-4">
                                            <small>{r.h} : H/d</small>
                                            <small>{r.f} : F/s</small>
                                            <button className="btn btn-danger px-1 py-0 ml-2" onClick={() => {
                                                removeAcli(idx);
                                            }}><i className="bi bi-dash"></i></button>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </>
                    :
                    <>
                    </>
            }
        </li>
    }

    useLayoutEffect(() => {
        p.onChange(
            {
                "iluminacion": lamps,
                "electrodomesticos": p.labels["electrodomesticos"],
                "aclimatadores": p.labels["aclimatadores"],
                "transporte": p.labels["transporte"],
                "vacaciones": p.labels["vacaciones"],
                "alimentos": p.labels["alimentos"],
                "papeles": p.labels["papeles"],
                "user": p.labels["user"],
            }
        );
    }, [lamps]);
    useLayoutEffect(() => {
        p.onChange(
            {
                "iluminacion": p.labels["iluminacion"],
                "electrodomesticos": electros,
                "aclimatadores": p.labels["aclimatadores"],
                "transporte": p.labels["transporte"],
                "vacaciones": p.labels["vacaciones"],
                "alimentos": p.labels["alimentos"],
                "papeles": p.labels["papeles"],
                "user": p.labels["user"],
            }
        );
    }, [electros]);
    useLayoutEffect(() => {
        p.onChange(
            {
                "iluminacion": p.labels["iluminacion"],
                "electrodomesticos": p.labels["electrodomesticos"],
                "aclimatadores": acli,
                "transporte": p.labels["transporte"],
                "vacaciones": p.labels["vacaciones"],
                "alimentos": p.labels["alimentos"],
                "papeles": p.labels["papeles"],
                "user": p.labels["user"],
            }
        );
    }, [acli]);

    return <div className="d-flex flex-column w-100 h-100">
        <div className="vivienda-form form ">
            <ul className="gap-3 d-flex flex-column px-0">
                <Iluminacion />
                <hr />
                <Electrodomesticos />
                <hr />
                <Aclimatadores />
            </ul>
        </div>
        <div className="d-flex flex-row justify-content-end pt-4 px-2">
            <button className="btn btn-primary d-flex align-items-center flex-row gap-2 px-4 py-2" onClick={() => {
                p.changeSlide(1)
            }}>
                Siguiente
                <i className="bi bi-chevron-right"></i>
            </button>
        </div>
    </div>
}

export default Vivienda;